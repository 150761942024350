import React, {useState, useContext, useRef } from 'react';
import './Home.css';
import Sidebar from '../Components/Sidebar';
import Chat from '../Components/Chat';
import { ConversationsContext } from '../ConversationsContext';
import openSidebar from '../assets/open-sidebar.png';
import { storeShareableConversation } from '../api';


function Home({isLoading, createNewConversation}) {    
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [inputContent, setInputContent] = useState('');
    const [disclaimerModalOpen, setDisclaimerModalOpen] = useState(false);
    const chatRef = useRef();

    const setAndSubmitInputContent = (input) => {
        chatRef.current.childFunction(input);
      };

    const {
        conversations,
        setConversations,
        activeConversation,
    } = useContext(ConversationsContext);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const shareChat = () => {
        const activeConv = conversations.find(conv => conv.id === activeConversation);
        storeShareableConversation(activeConv);
    }

    return (
        <div className="app-container">
            {!isSidebarOpen && (
                <button className="sidebar-toggle-btn" onClick={toggleSidebar} aria-label="Toggle sidebar">
                <img src={openSidebar} alt="Close Sidebar" />
                </button>
            )}

            <Sidebar isOpen={isSidebarOpen} createNewConversation={createNewConversation} shareChat={shareChat} setAndSubmitInputContent={setAndSubmitInputContent} setIsSidebarOpen={setIsSidebarOpen} setDisclaimerModalOpen={setDisclaimerModalOpen} />
            {
                isLoading ? 
                <div className="loading-container">
                    <div className="loading-spinner"></div>
                    <div className="loading-text">Loading...</div>
                </div>
                : 
                <Chat
                ref={chatRef}
                inputContent={inputContent}
                setInputContent={setInputContent}
                setDisclaimerModalOpen={setDisclaimerModalOpen}
                setAndSubmitInputContent={setAndSubmitInputContent}
              />
            }

            {disclaimerModalOpen && (
                <div className="disclaimer-modal-backdrop" onClick={() => setDisclaimerModalOpen(false)}>
                    <div className="disclaimer-modal-content" onClick={e => e.stopPropagation()}>
                        <h2>About Max</h2>
                        <p>
                        MAX is generative AI built on the knowledge of the Formations tax team, not external sources, so you can rest assured that you won’t get errant information from an untrusted source. Even still, MAX can make mistakes. You should always consult with a [human] financial expert before making important decisions. Your personal and company data are protected in this chat.
                        </p>
                        <button className="disclaimer-modal-button" onClick={() => setDisclaimerModalOpen(false)}>Close</button>
                    </div>
                </div>
            )}
        </div> 
    );
}

export default Home;
