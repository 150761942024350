import React, { useState, useEffect } from 'react';
import '../Components/Chat.css';
import userImage from '../assets/user-profile.png';
import assistantImage from '../assets/max-assistant-profile.png';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import { getShareableConversation } from '../api';
import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import './StaticConversation.css';

function StaticConversation() {
    const { conversation_id, email } = useParams();
    const [conversation, setConversation] = useState(null);
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const isDemo = queryParams.get('isDemo') === 'true';

    useEffect(() => {
        (async () => {
            try {
                const response = await getShareableConversation(conversation_id, email, isDemo);
                if (response.data) {
                    setConversation(response.data);
                }
            } catch (error) {
                console.error("Error fetching conversation: ", error.message);
            }
        })();
    }, [conversation_id, email, isDemo]);

    const renderFeedbackIcon = (feedbackType) => {
        if (feedbackType === 'up') return <FaThumbsUp className="sc-icon-thumb-up sc-highlighted-green" />;
        if (feedbackType === 'down') return <FaThumbsDown className="sc-icon-thumb-down sc-highlighted-red" />;
        return null;
    };

    return (
        <div className="sc-chat-container">
            <div className="sc-navbar">
                <a href="/" className="sc-navbar-title">Formations Tax Assistant</a>
            </div>
            <div className="sc-messages-section">
                {conversation && conversation.messages.map((message, index) => (
                    <div key={index} className={`sc-message-wrapper ${message.role}`}>
                        <div className={`sc-message ${message.role}`}>
                            <div className="sc-profile-square" style={{ backgroundImage: `url(${message.role === 'user' ? userImage : assistantImage})` }}></div>
                            <div className="sc-message-content-feedback-wrapper">
                                <ReactMarkdown className="sc-white-links">{message.content}</ReactMarkdown>
                                {message.role === 'assistant' && (
                                    <div className="sc-feedback-and-comment">
                                        {renderFeedbackIcon(message.feedbackType)}
                                        {message.comment && <div className="sc-comment">{message.comment}</div>}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
    
    
    
}

export default StaticConversation;

