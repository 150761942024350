import React, { useState, useContext, useRef, useEffect, useImperativeHandle, forwardRef } from 'react';
import './Chat.css';
import { getChatCompletion, storeAllData } from '../api';
import FeedbackModal from './FeedbackModal';
import { ConversationsContext } from '../ConversationsContext';
import MessageDisplay from './MessageDisplay';
import shieldIcon from '../assets/shield-icon.png';
import agentHeadIcon from '../assets/agent-head-icon.png';
import userProfile from '../assets/user-profile.png';
import sendIcon from '../assets/send-icon.png';
import xIcon from '../assets/x-icon.png';
import moreIconHorizontal from '../assets/more-icon-horizontal.png'
import downloadIcon from '../assets/download-icon.png';
import ChatNavbar from './ChatNavbar';
import { Document, Packer, Paragraph, TextRun } from 'docx';
import ChatWelcomeMessage from './ChatWelcomeMessage';
import ContactFormations from '../Pages/ContactFormations';

const Chat = forwardRef(({ inputContent, setInputContent, toggleSidebar, setDisclaimerModalOpen, setAndSubmitInputContent}, ref) => {
    const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
    const [selectedMessageIndex, setSelectedMessageIndex] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const inputRef = useRef(null);
    const [contactConversationID, setcontactConversationID] = useState(null);
    const [reason, setReason] = useState(null);
    const [isContactModalVisible, setIsContactModalVisible] = useState(false);
    const [userQuestion, setUserQuestion] = useState('');

    useImperativeHandle(ref, () => ({
        childFunction: (input) => handleSendMessage(input),
      }));

    const {
        conversations, 
        setConversations,
        activeConversation: activeConversationId,
        setIsStreaming,
        email,
        isDemo,
        widgetInput
    } = useContext(ConversationsContext);

    const messagesEndRef = useRef(null);

    const activeConversation = conversations.find(c => c.id === activeConversationId);

    useEffect(() => {
        if (widgetInput) {
            setInputContent(widgetInput);
        }
    }, []);


    useEffect(() => {
        const scrollContainer = messagesEndRef.current?.parentNode;
        if (scrollContainer) {
            const scrollHeight = scrollContainer.scrollHeight;
            const height = scrollContainer.clientHeight;
            const maxScrollTop = scrollHeight - height;
            scrollContainer.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
        }
    }, [activeConversation?.messages]);

    useEffect(() => {
        if (!isLoading) {
            inputRef.current?.focus();
        }
    }, [isLoading]);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
        }
    };

    if (!activeConversation) return null;

    const handlePreCannedQuestion = (question) => {
        handleSendMessage(question);
    };

    const updateConversationMessages = (conversations, conversationId, newMessages) => {
        return conversations.map(conversation => {
            if (conversation.id === conversationId) {
                return { ...conversation, messages: newMessages };
            }
            return conversation;
        });
    };

    const setLoadingAndStreaming = (isLoading, isStreaming) => {
        setIsLoading(isLoading);
        setIsStreaming(isStreaming);
    };

    const prepareMessagePayload = (messages, contentToSend, senderRole) => {
        return messages.map(({ comment, feedbackType, temp, thinking, ...keepAttrs }) => keepAttrs)
            .concat({ role: senderRole, content: contentToSend });
    };

    const handleSendMessage = (messageContent = inputContent) => {
        const contentToSend = (messageContent || inputContent).trim();
        if (!contentToSend) return;

        setLoadingAndStreaming(true, true);
        setInputContent('');

        const { id: conversationId, messages } = activeConversation;
        const updatedMessages = [
            ...messages,
            { role: 'user', content: contentToSend },
            { role: 'assistant', content: contentToSend, temp: true, thinking: true }
        ];

        setConversations(prevConversations => updateConversationMessages(prevConversations, conversationId, updatedMessages));

        const payload = { id: conversationId, messages: prepareMessagePayload(messages, contentToSend, 'user') };
        getChatCompletion(payload, (chunk, isDone) => {
            handleStreamedResponse(activeConversation.id, chunk, isDone, () => {
                if (isDone) {
                    storeAllData(conversations, email, isDemo);
                    setLoadingAndStreaming(false, false);
                }
            });
        }, handleNetworkError, setIsStreaming);
    };

    const handleNetworkError = () => {
        setLoadingAndStreaming(false, false);
        setInputContent('');

        setConversations(prevConversations => prevConversations.map(conversation => {
            if (conversation.id === activeConversation.id) {
                return { ...conversation, networkErrorActive: true };
            }
            return conversation;
        }));
    };

    const handleStreamedResponse = (conversationId, chunk, isDone) => {
        if (isDone || !chunk) {
            setLoadingAndStreaming(false, false);
            return;
        }

        setConversations(prevConversations => {
            return prevConversations.map(conversation => {
                if (conversation.id === conversationId) {
                    const newMessages = conversation.messages.slice();
                    const lastMessage = newMessages[newMessages.length - 1];

                    if (lastMessage && lastMessage.thinking) {
                        lastMessage.content = chunk;
                        delete lastMessage.thinking;
                    } else {
                        lastMessage.content += chunk;
                    }

                    return { ...conversation, messages: newMessages };
                }
                return conversation;
            });
        });
    };

    const handleRegenerateClick = () => {
        const { id: conversationId, messages } = activeConversation;

        const lastAssistantMessageIndex = messages.slice().reverse().findIndex(m => m.role === 'assistant');
        if (lastAssistantMessageIndex === -1) return;

        const correctIndex = messages.length - 1 - lastAssistantMessageIndex;

        const lastUserMessage = messages.slice().reverse().find(m => m.role === 'user')?.content;
        if (!lastUserMessage) return;

        setLoadingAndStreaming(true, true);

        let updatedMessages = [...messages];
        updatedMessages[correctIndex] = { role: 'assistant', content: '', temp: true, thinking: true };

        setConversations(prevConversations => prevConversations.map(conversation => {
            if (conversation.id === conversationId) {
                return {
                    ...conversation,
                    messages: updatedMessages,
                    networkErrorActive: false
                };
            }
            return conversation;
        }));

        const payload = { id: conversationId, messages: prepareMessagePayload(updatedMessages, lastUserMessage, 'user') };
        getChatCompletion(payload, (chunk, isDone) => {
            handleStreamedResponse(activeConversation.id, chunk, isDone, () => {
                if (isDone) {
                    storeAllData(conversations, email, isDemo);
                    setLoadingAndStreaming(false, false);
                }
            });
        }, handleNetworkError, setIsStreaming);
    };

    const updateMessageInConversation = (messageIndex, updatedProperties) => {
        const currentMessages = [...activeConversation.messages];
        const updatedMessage = {
            ...currentMessages[messageIndex],
            ...updatedProperties
        };
        currentMessages[messageIndex] = updatedMessage;

        const updatedConversations = conversations.map(conv =>
            conv.id === activeConversation.id ? { ...conv, messages: currentMessages } : conv
        );

        setConversations(updatedConversations);
    };

    const handleThumbFeedback = (messageId, feedbackType) => {
        updateMessageInConversation(messageId, { feedbackType });
    };

    const handleFeedbackSubmit = (feedbackComment) => {
        updateMessageInConversation(selectedMessageIndex, { comment: feedbackComment });

        setIsFeedbackModalOpen(false);
        setSelectedMessageIndex(null);
    };

    const downloadConversationAsDocx = async () => {
        const conversation = activeConversation;
        const doc = new Document({
            sections: [{
                properties: {},
                children: conversation.messages.map(message => 
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: `${message.role}: `,
                                bold: true,
                            }),
                            new TextRun(message.content),
                        ],
                        spacing: { after: 200 },
                    })
                ),
            }],
        });

        const blob = await Packer.toBlob(doc);

        const url = URL.createObjectURL(blob);

        const downloadElement = document.createElement("a");
        downloadElement.href = url;
        downloadElement.download = `${conversation.name || 'Conversation'}.docx`; 
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
    };

    const handleContactClicked = (href) => {
        const parts = href.split('/');
        const hasQuestionInHref = href.toLowerCase().includes("question");
        const conversationID = parts[parts.length - 2];
        
        const reason = parts[parts.length - 1];
        
        setcontactConversationID(conversationID);
        setReason(reason);
        
        const conversation = conversations.find(convo => convo.id.toString() === conversationID);
        if (conversation && conversation.messages) {
            const userMessages = conversation.messages.filter(message => message.role === "user");
            const lastUserMessage = userMessages[userMessages.length - 1];
            setUserQuestion(lastUserMessage);
            
            if (lastUserMessage) {
                console.log("Last User Message: ", lastUserMessage.content);
            }
        }
        
        setIsContactModalVisible(true);
    };
    

    return (
        <div className="chat-container">
            <ChatNavbar />
            <button 
                className="download-button" 
                onClick={() => {downloadConversationAsDocx()}}
                title="Download the current chat" 
            >
                <img src={downloadIcon} alt="Download" className="download-icon"/>
            </button>

            {isContactModalVisible && <ContactFormations conversationID={contactConversationID} reason={reason} setIsContactModalVisible={setIsContactModalVisible} userQuestion={userQuestion}/>}

            <div className="messages-section">
                <ChatWelcomeMessage setAndSubmitInputContent={setAndSubmitInputContent} setDisclaimerModalOpen={setDisclaimerModalOpen}/>
                {activeConversation.messages.map((message, index) => (
                    <MessageDisplay
                        key={index}
                        message={message}
                        index={index}
                        handleThumbFeedback={handleThumbFeedback}
                        handleCommentClick={(messageIndex) => {
                            setSelectedMessageIndex(messageIndex);
                            setIsFeedbackModalOpen(true);
                        }}
                        handleContactClicked={handleContactClicked}
                    />
                ))}
                <div ref={messagesEndRef} />
            </div>

            <div className="bottom-section">
                {activeConversation.networkErrorActive ? (
                    <div className="regenerate-container">
                        <p className="regenerate-message">There was a network error, please regenerate.</p>
                        <button className="regenerate-btn" onClick={handleRegenerateClick}>Regenerate</button>
                    </div>
                ) : (
                    <div className="input-container">
                        <img src={userProfile} alt="" className="user-profile-img"/>
                        <textarea
                            ref={inputRef}
                            className="input-field"
                            placeholder="Ask me anything..."
                            value={inputContent}
                            disabled={isLoading}
                            onChange={(e) => setInputContent(e.target.value)}
                            onKeyDown={handleKeyDown}
                        ></textarea>
                        
                        {inputContent && (
                            <>
                                <button className="icon-button send-button" onClick={() => handleSendMessage(inputContent)}>
                                    <img src={sendIcon} alt="Send" className="icon-img"/>
                                </button>
                                <button className="icon-button clear-button" onClick={() => setInputContent('')}>
                                    <img src={xIcon} alt="Clear" className="icon-img"/>
                                </button>
                            </>
                        )}
                    </div>
                    
                )}
                <div className="disclaimer">
                    <p>
                        <img src={shieldIcon} alt="Shield Icon" /> Your personal and company data are protected in this chat.
                    </p>
                    <p>
                        <span className="clickable-disclaimer" onClick={() => setDisclaimerModalOpen(true)}>
                            <img src={agentHeadIcon} alt="Agent Head Icon" /> MAX is a generative AI agent and may provide varying levels of accuracy.
                        </span>
                    </p>
                </div>
            </div>

            {isFeedbackModalOpen && (
                <div className="modal-wrapper">
                    <FeedbackModal 
                        isVisible={isFeedbackModalOpen} 
                        feedbackType={selectedMessageIndex !== null ? activeConversation.messages[selectedMessageIndex].feedbackType : null} 
                        initialComment={selectedMessageIndex !== null ? activeConversation.messages[selectedMessageIndex].comment : null} 
                        onClose={() => setIsFeedbackModalOpen(false)} 
                        onSubmit={handleFeedbackSubmit}
                    />
                </div>
            )}

        </div>
    );
});

export default Chat;
