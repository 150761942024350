import React from 'react';
import './FeedbackModal.css';

function FeedbackModal({ isVisible, onClose, onSubmit, feedbackType, initialComment}) {
    const [comment, setComment] = React.useState(initialComment || "");

    const handleSubmit = () => {
        onSubmit(comment);
        onClose();
    };

    if (!isVisible) return null;

    return (
        <div className="feedback-modal-overlay">
            <div className={`feedback-modal-container ${feedbackType === 'up' ? 'feedback-thumbs-up' : 'feedback-thumbs-down'}`}>
                <div className="feedback-modal-header">
                    <div className={`feedback-icon ${feedbackType}`}>
                        {feedbackType === 'up' ? '👍 Good Response' : '👎 Bad Response'}
                    </div>
                </div>
                <textarea
                    className="feedback-modal-textarea"
                    placeholder="Additional comments..."
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                />
                <div className="feedback-modal-actions">
                    <button className="feedback-modal-button" onClick={onClose}>Close</button>
                    <button className="feedback-modal-button" onClick={handleSubmit}>Submit</button>
                </div>
            </div>
        </div>
    );
    
}

export default FeedbackModal;
