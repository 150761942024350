import React, {useState, useEffect, useContext} from 'react';
import './Sidebar.css';
import { ConversationsContext } from '../ConversationsContext';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { archiveConversation, archiveAllConversations } from '../api';
import bookIcon from '../assets/book-icon-sidebar.png'
import moreIcon from '../assets/more-icon.png';
import copyIcon from '../assets/copy-icon.png';
import plusIcon from '../assets/plus-icon.png';
import shieldIcon from '../assets/shield-icon-blue.png';
import arrowBackIcon from '../assets/close-sidebar.png';
import taxCalculatorIcon from '../assets/tax-savings-calculator-icon.png';
import { Packer, Paragraph, TextRun, Document } from "docx";

function Sidebar({ isOpen, createNewConversation, shareChat, setAndSubmitInputContent, setIsSidebarOpen, setDisclaimerModalOpen }) {
    const [isDemo, setIsDemo] = useState(localStorage.getItem('isDemo') === "true");
    const [activeGroup, setActiveGroup] = useState('Today');
    const [isModalVisible, setModalVisible] = useState(false);
    const [activeDropdown, setActiveDropdown] = useState(null);
    const [renamingConversation, setRenamingConversation] = useState(null);
    const [renameValue, setRenameValue] = useState("");

    const popularQuestions = [
        "As a small business, how can I reduce my taxes?",
        "Can I get some of the same tax advantages of large companies?",
        "What can I tell you about my business?"
    ];

    const {
        conversations,
        setConversations,
        activeConversation,
        setActiveConversation,
        email
    } = useContext(ConversationsContext);

    const handleClearHistory = async () => {
        try {
            const response = await archiveAllConversations(conversations, email);

            if (response.message === "Conversation archived successfully") {
                setConversations([]);
                setActiveConversation(null);
            } else {
                console.error("Error in archiving conversations: ", response.message);
            }
        } catch (error) {
            console.error("API call failed: ", error);
        }

        window.location.reload();
    };

    const groupConversationsByDate = (conversations) => {
        const grouped = {};
        const today = new Date().toDateString();
    
        conversations.forEach((conversation) => {
            const conversationDate = new Date(conversation.id).toDateString();
            const conversationMonthYear = conversationDate.slice(4, 7) + ' ' + conversationDate.slice(11, 15);
    
            if (conversationDate === today) {
                if (!grouped['Today']) {
                    grouped['Today'] = [];
                }
                grouped['Today'].unshift(conversation);
            } else {
                if (!grouped[conversationMonthYear]) {
                    grouped[conversationMonthYear] = [];
                }
                grouped[conversationMonthYear].unshift(conversation);
            }
        });
    
        // Separate "Today" group from others
        const { Today, ...otherGroups } = grouped;
        const sortedOtherGroupKeys = Object.keys(otherGroups).sort((a, b) => new Date(b) - new Date(a));
        // Combine "Today" and sorted other groups
        const sortedGrouped = Today ? { 'Today': Today } : {};
        sortedOtherGroupKeys.forEach((key) => {
            sortedGrouped[key] = otherGroups[key];
        });
    
        return sortedGrouped;
    };
      
    const groupedConversations = groupConversationsByDate(conversations);
      
    const toggleGroup = (groupName) => {
        setActiveGroup(activeGroup === groupName ? null : groupName);
    };
    

    const handleScheduleCall = () => {
        window.open('https://get.formationscorp.com/meetings/uribarjoseph/meet-with-formations', '_blank');
    };

    const handleTaxSavingsCalculator = () => {
        window.open('https://calculator.formationscorp.com/', '_blank');
    };

    const handleShare = async (conversationId) => {
        const currentDomain = window.location.origin;
        const urlToShare = isDemo
            ? `${currentDomain}/view-conversation/${conversationId}/${email}?isDemo=true`
            : `${currentDomain}/view-conversation/${conversationId}/${email}`;
    
        if (navigator.clipboard) { 
            try {
                await navigator.clipboard.writeText(urlToShare);
                alert("Copied to clipboard"); 
            } catch (err) {
                console.error("Failed to copy: ", err);
            }
        } else {
            console.log("Clipboard API not available.");
        }
    
        setActiveDropdown(null);
    };
    

    const getFirstUserMessage = (conversation) => {
        const userMessage = conversation.messages.find(msg => msg.role === 'user');
        if (userMessage) {
            return userMessage.content.length > 18 
                ? userMessage.content.substring(0, 18) + '...' 
                : userMessage.content;
        } 
        return ''; 
    }

    const handleDownload = (conversationId) => {
        setActiveDropdown(null);
    };

    const handleDeleteConversation = async (conversationId) => {
        const conversationToArchive = conversations.find(conv => conv.id === conversationId);
        
        if (conversationToArchive) {
            try {
                await archiveConversation(conversationToArchive, email);
                
                const updatedConversations = conversations.filter(conv => conv.id !== conversationId);
                // console.log("Updated Conversations", updatedConversations);
                setConversations(updatedConversations);
                setActiveConversation(updatedConversations[updatedConversations.length - 1].id);
    
                if (activeConversation === conversationId) {
                    if (updatedConversations.length === 0) {
                        createNewConversation();
                    }
                }
    
            } catch (error) {
                console.error("Error archiving the conversation:", error);
            }
        }
        setActiveDropdown(null);
    };
    
    const toggleDropdown = (id) => {
        setActiveDropdown(activeDropdown === id ? null : id);
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (activeDropdown && !event.target.closest('.conversation')) {
                setActiveDropdown(null);
            }
        };
    
        document.addEventListener('mousedown', handleOutsideClick);
        return () => document.removeEventListener('mousedown', handleOutsideClick);
    }, [activeDropdown]);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (renamingConversation && !event.target.closest('.conversation')) {
                setRenamingConversation(null); // Close the renaming input
                setRenameValue(""); // Optionally reset the rename value
            }
        };
    
        document.addEventListener('mousedown', handleOutsideClick);
        return () => document.removeEventListener('mousedown', handleOutsideClick);
    }, [renamingConversation]); // Ensure this effect depends on renamingConversation
    

    const handleRename = (conversationId) => {
        const currentName = conversations.find(conv => conv.id === conversationId).name;
        setRenameValue(currentName || ''); 
        setRenamingConversation(conversationId); // Set the conversation to be renamed
        setActiveDropdown(null);
    };

    const handleRenameSubmit = async (conversationId) => {
        const updatedName = renameValue.trim();
        if (!updatedName) {
            alert("Conversation name cannot be empty.");
            return;
        }
        
        // console.log(`Renaming conversation ${conversationId} to ${updatedName}`);
        
        const updatedConversations = conversations.map(conv => 
            conv.id === conversationId ? { ...conv, name: updatedName } : conv
        );
        setConversations(updatedConversations);
        
        setRenamingConversation(null);
    };

    const downloadConversationAsDocx = async (conversation) => {
        setActiveDropdown(null);
        const doc = new Document({
            sections: [{
                properties: {},
                children: conversation.messages.map(message => 
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: `${message.role}: `,
                                bold: true,
                            }),
                            new TextRun(message.content),
                        ],
                        spacing: { after: 200 },
                    })
                ),
            }],
        });
    
        const blob = await Packer.toBlob(doc);
    
        const url = URL.createObjectURL(blob);
    
        const downloadElement = document.createElement("a");
        downloadElement.href = url;
        downloadElement.download = `${conversation.name || 'Conversation'}.docx`; 
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
    };

    const handleNewConversationClicked = () => {
        createNewConversation();
    }
    

    return (
        <div className={`sidebar-container ${isOpen ? 'open' : ''}`}>
            <div className="sidebar-header">
                <div className="header-left">
                    My MAX History
                </div>
                <div className="header-right">
                    <button className="back-to-chat" onClick={() => setIsSidebarOpen(false)}>
                        <img src={arrowBackIcon} alt="Back"/> Back to chat
                    </button>
                </div>
            </div>



            
            <div className="new-conversation-btn-container">
                <button className="new-conversation-btn" onClick={handleNewConversationClicked}><img src={plusIcon}/>New Conversation</button>
            </div>

            <div className="threads-section">
                {Object.keys(groupedConversations).map(groupName => (
                    <div key={groupName}>
                        <div className="group-header" onClick={() => toggleGroup(groupName)}>
                            <span className="group-name-and-count">
                                {groupName} [{groupedConversations[groupName].length}]
                            </span>
                            <span className="dropdown-icon">
                                {activeGroup === groupName ? <FaChevronUp /> : <FaChevronDown />}
                            </span>
                        </div>

                        {activeGroup === groupName && groupedConversations[groupName].map(conversation => (
                            <div key={conversation.id}
                                className={`conversation ${activeConversation === conversation.id ? 'active-conversation' : ''}`}
                                onClick={() => setActiveConversation(conversation.id)}>
                                
                                {renamingConversation === conversation.id ? (
                                    <input 
                                        type="text" 
                                        value={renameValue}
                                        onChange={(e) => setRenameValue(e.target.value)}
                                        onBlur={() => setRenamingConversation(null)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                handleRenameSubmit(conversation.id, e.target.value);
                                            } else if (e.key === 'Escape') {
                                                setRenamingConversation(null);
                                            }
                                        }}
                                        autoFocus
                                    />
                                ) : (
                                    <div onDoubleClick={() => handleRename(conversation.id)}>
                                        {conversation.name || getFirstUserMessage(conversation) || 'New Conversation'}
                                    </div>
                                )}

                                <div className="more-icon-container" onClick={(e) => { e.stopPropagation(); toggleDropdown(conversation.id); }}>
                                    <img src={moreIcon} alt="More" />
                                </div>
                                {activeDropdown === conversation.id && (
                                    <div className="dropdown-menu">
                                        <button onClick={() => handleRename(conversation.id)}>Rename</button>
                                        <button onClick={() => handleShare(conversation.id)}>Share</button>
                                        <button onClick={() => downloadConversationAsDocx(conversation)}>Download</button>
                                        <button onClick={() => handleDeleteConversation(conversation.id)}>Delete</button>
                                    </div>
                                )}
                            </div>
                        ))}

                    </div>
                ))}
                <div className="group-header-popular-questions">Popular Questions</div>
                {popularQuestions.map((question, index) => (
                    <div key={index} className="popular-question" onClick={() => { setAndSubmitInputContent(question) }}>
                        <span>{question}</span>
                        <img 
                            src={copyIcon} 
                            alt="Copy" 
                            className="copy-icon" 
                            onClick={(e) => {
                                e.stopPropagation();
                                navigator.clipboard.writeText(question).then(() => {
                                    alert("Copied to clipboard");
                                }, (err) => {
                                    console.error("Failed to copy: ", err);
                                });
                            }} 
                        />
                    </div>
                ))}

            </div>

            <div className="sidebar-footer">
            
                <button className="mobile-btn" onClick={() => setDisclaimerModalOpen(true)}>
                    <img src={shieldIcon}/>AI Disclaimer & Privacy Commitment
                </button>
                <button className="tax-calculator-btn" onClick={handleTaxSavingsCalculator}>
                    <img src={taxCalculatorIcon}/>Tax Savings Calculator
                </button>
                <button className="schedule-btn" onClick={handleScheduleCall}>
                    <img src={bookIcon}/>Talk to a tax advisor
                </button>
            </div>
        </div>
    );
}

export default Sidebar;