import { createContext } from "react";

export const ConversationsContext = createContext({
    conversations: [],
    setConversations: () => {},
    activeConversation: null,
    setActiveConversation: () => {},
    email: '',
    setEmail: () => {},
    setIsStreaming: false,
    isDemo: false,
    widgetInput: ''
});
