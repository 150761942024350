import React, { useState } from 'react';
import './ChatWelcomeMessage.css';

function ChatWelcomeMessage({setAndSubmitInputContent, setDisclaimerModalOpen}) {

  const handleQuestionClick = (question) => {
    const formattedQuestion = question.replace(/\s+/g, ' ').trim();
    setAndSubmitInputContent(formattedQuestion);
  };

  const handleOpenModal = (e) => {
    e.preventDefault(); 
    setDisclaimerModalOpen(true); 
  };

  return (
    <div className="chat-welcome-message">
      <p className="custom-h1">Hi! 👋 I'm <span className="custom-h1-bold">MAX</span>, your AI-powered guide to tax savings...</p>
      <p className="welcome-message">Think of me as your digital CPA -- except unlike your CPA, I'm ALWAYS here to help! My role is to help you maximize your understanding of the tax strategies and benefits available to the self-employed. Let's get started! <a href="/" onClick={handleOpenModal} style={{cursor: 'pointer'}}> [Disclaimer]</a></p>
      <p className="custom-h2">Example Discussions with <span className="custom-h2-bold">MAX</span></p>
      <div className="section-container">
        <div className="section">
          <p>Topic: <b>Compare Incorporations</b></p>
          <button className="sample-question" onClick={() => handleQuestionClick("What’s the difference between an LLC and an S-Corp?")}>What’s the difference between an LLC and an S-Corp?</button>
        </div>

        <div className="section">
          <p>Topic: <b>Timing of Expenses</b></p>
          <button className="sample-question" onClick={() => handleQuestionClick("When should I declare my expenses? Daily, Weekly, Monthly, or bi-Annually?")}>When should I declare my expenses? Daily, Weekly, Monthly, or bi-Annually?</button>
        </div>

        <div className="section">
          <p>Topic: <b>About Formations</b></p>
          <button className="sample-question" onClick={() => handleQuestionClick("What can Formations do for my self-employed business?")}>What can Formations do for my self-employed business?</button>
        </div>
      </div>
    </div>
  );
}

export default ChatWelcomeMessage;