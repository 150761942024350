import React from 'react';
import ReactMarkdown from 'react-markdown';
import userImage from '../assets/user-profile.png';
import assistantImage from '../assets/max-assistant-profile.png';
import defaultCommentIcon from '../assets/comment-icon-default.png';
import selectedCommentIcon from '../assets/comment-icon-selected.png';
import copyIcon from '../assets/copy-icon.png';
import defaultThumbsUp from '../assets/thumbs-up-default.png';
import selectedThumbsUp from '../assets/thumbs-up-selected.png';
import defaultThumbsDown from '../assets/thumbs-down-default.png';
import selectedThumbsDown from '../assets/thumbs-down-selected.png';
import './MessageDisplay.css';
import {FaRegCalendarAlt, FaQuestionCircle} from 'react-icons/fa';

const MessageDisplay = ({ message, index, handleThumbFeedback, handleCommentClick, handleContactClicked }) => {
    const isAssistant = message.role === 'assistant';

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(message.content);
            alert("Message copied to clipboard!");
        } catch (err) {
            console.error('Failed to copy: ', err);
            alert("Failed to copy message.");
        }
    }

    const CustomButton = ({ href, children, ...props }) => {
        const isBlogLink = children.toString().toLowerCase().includes("blog");
        const hasQuestionInHref = href.toLowerCase().includes("question");
    
        const handleClick = (event) => {
            if (isBlogLink) {
                return;
            }
    
            event.preventDefault();
            handleContactClicked(href);
        };
    
        if (isBlogLink) {
            return (
                <a href={href} target="_blank" rel="noopener noreferrer" {...props}>{children}</a>
            );
        }else{
            const Icon = hasQuestionInHref ? FaQuestionCircle : FaRegCalendarAlt;
            return (
                <button className="message-calendar-button" onClick={handleClick} {...props}>
                    <Icon />{children}
                </button>
            );
        }
    
        
    };
    

    return (
        <div className={`message-wrapper ${isAssistant ? 'assistant' : ''}`}>
            <div className={`message-content ${isAssistant ? 'assistant-message-content' : ''}`}>
                <div className={`message ${message.role}`}>
                    <div className="profile-square" style={{ backgroundImage: `url(${isAssistant ? assistantImage : userImage})` }}></div>
                    {message.thinking ? 
                        <span className="thinking-animation">...</span> :
                        <ReactMarkdown className="markdown-content" components={{ a: CustomButton }}>
                            {message.content}
                        </ReactMarkdown>
                    }
                    {isAssistant && (
                        <div className="grey-bottom-section">
                            <div className="icons-container">
                                <img 
                                    src={message.feedbackType === 'up' ? selectedThumbsUp : defaultThumbsUp} 
                                    alt="Thumbs Up" 
                                    className="icon-thumb-up" 
                                    onClick={() => handleThumbFeedback(index, 'up')} 
                                />
                                <img 
                                    src={message.feedbackType === 'down' ? selectedThumbsDown : defaultThumbsDown} 
                                    alt="Thumbs Down" 
                                    className="icon-thumb-down" 
                                    onClick={() => handleThumbFeedback(index, 'down')} 
                                />
                                {message.feedbackType && (
                                    <img 
                                        src={message.comment ? selectedCommentIcon : defaultCommentIcon} 
                                        alt="Comment" 
                                        className={`icon-comment ${message.comment ? 'commented' : ''}`}
                                        onClick={() => handleCommentClick(index)} 
                                    />
                                )}
                                <img 
                                    src={copyIcon} 
                                    alt="Copy" 
                                    className="icon-copy" 
                                    onClick={handleCopy} 
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MessageDisplay;