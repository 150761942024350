import React from 'react';
import './ChatNavbar.css'; 
import brandIcon from '../assets/max-nav-bar-brand.png';
import bookIcon from '../assets/book-call-icon.png';

const ChatNavbar = () => {
    return (
        <div className="navbar">
            <div className="navbar-brand">
                <img src={brandIcon} alt="MAX" />
            </div>
            <div className="navbar-text left desktop-text">
                MAX is your AI tax companion, helping the self-employed navigate your financial options.
            </div>
            <a href="https://get.formationscorp.com/meetings/uribarjoseph/meet-with-formations" target="_blank" rel="noopener noreferrer" className="navbar-text right desktop-text">
                Book a call with a Formations team member <img src={bookIcon} alt="Book" className="call-icon"/>
            </a>
            <div className="navbar-text mobile-text">
                Your AI companion, helping introduce you to a world of financial smarts.
            </div>
        </div>
    );
};

export default ChatNavbar;
