import React, { useState } from 'react';
import './FormationsNavBar.css';
import formationsLogo from '../assets/formations-logo.png'; 

function TopNavBar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navigateToGetStarted = () => {
    window.open('https://form.typeform.com/to/ASr1cAI1?utm_campaign=Apply%20now%20form&utm_source=website', '_blank');
  };

  return (
    <div className="top-nav-bar">
      <a href="https://formationscorp.com/" target="_blank" rel="noopener noreferrer">
        <img src={formationsLogo} alt="Formations Logo" className="nav-logo" />
      </a>
      <div className={`nav-items`}>
        <button className="get-started" onClick={navigateToGetStarted}>Get Started</button>
      </div>
    </div>
  );
}

export default TopNavBar;
