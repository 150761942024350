import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Summary from './Pages/Summary';
import ContactFormations from './Pages/ContactFormations';
import Home from './Pages/Home';
import AdminView from './Pages/AdminView';
import PrivateRoute from './Pages/PrivateRoute';
import { ConversationsContext } from './ConversationsContext';
import { storeAllData, getAllConversations } from './api';
import StaticConversation from './Pages/StaticConversation';
import './App.css';
import { v4 as uuidv4 } from 'uuid';
import FormationsNavBar from './Components/FormationsNavBar';

function App() {
    const [conversations, setConversations] = useState([]);
    const [activeConversation, setActiveConversation] = useState(null);
    const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);
    const [isStreaming, setIsStreaming] = useState(false);
    const [email, setEmail] = useState(localStorage.getItem('userEmail') || '');
    const [isDemo, setIsDemo] = useState(localStorage.getItem('isDemo') === 'true');
    const [isDemoUpdated, setIsDemoUpdated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [widgetInput, setWidgetInput] = useState('');

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const path = location.pathname;
        if (!path.includes('/view-conversation') && !path.includes('/formations-admin') && !path.includes('/demo')) {
            const parseQueryParams = () => {
                const params = new URLSearchParams(location.search);
                return params.get('input');
            }
            const inputFromWidget = parseQueryParams();

            if(inputFromWidget){
                setWidgetInput(inputFromWidget);
            }
            navigate('/');
        }
        console.log("App Version: 2.0.14");
    }, [navigate]);

    useEffect(() => {
        const path = location.pathname;
        if (!path.includes('/view-conversation') && !path.includes('/formations-admin') && !path.includes('hubspot')) {
            const demoMode = path === '/demo';
            setIsDemo(demoMode);
            localStorage.setItem('isDemo', demoMode);

            let userEmail = localStorage.getItem('userEmail');
            if(!userEmail || userEmail =='contact-formations' || userEmail == 'view-conversation'){
                userEmail = uuidv4();
            }

            if(!demoMode && path != '/'){
                userEmail = path.split('/')[1];
            }

            localStorage.setItem('userEmail', userEmail);
            setEmail(userEmail);
            
        
            const _hsq = window._hsq = window._hsq || [];
            _hsq.push(["setPath", location.pathname]);
            _hsq.push(["trackPageView"]);
        
            setIsDemoUpdated(true);
        }
    }, [location]);

    useEffect(() => {
        if (!isInitialDataLoaded && isDemoUpdated) {
            fetchConversations();
            setIsDemoUpdated(false);
        }
    }, [isDemo, isDemoUpdated]);

    const getFirstUserMessage = (conversation) => {
        const userMessage = conversation.messages.find(msg => msg.role === 'user');
        if (userMessage) {
            return userMessage.content.length > 18 
                ? userMessage.content.substring(0, 18) + '...' 
                : userMessage.content;
        } 
        return 'New conversation'; 
    }
    

    async function fetchConversations() {
        setIsLoading(true);
        try {
            const responseData = await getAllConversations(email, isDemo);
            const conversationsData = responseData.data.messages;
            if (conversationsData && conversationsData.length > 0) {
                const updatedConversations = conversationsData.map(conversation => {
                    const conversationName = conversation.name || getFirstUserMessage(conversation);
                    return {
                        ...conversation,
                        name: conversationName,
                        networkErrorActive: conversation.networkErrorActive !== undefined ? conversation.networkErrorActive : false
                    };
                });
                setConversations(updatedConversations);
                const lastConversation = updatedConversations[updatedConversations.length - 1];
                if (!lastConversation.messages.length) {

                    updatedConversations[updatedConversations.length - 1] = {
                        ...lastConversation,
                        id: Date.now(),
                    };
                    setConversations(updatedConversations); 
                    setActiveConversation(updatedConversations[updatedConversations.length - 1].id); 
                } else {
                    createNewConversation();
                }
            } else {
                createNewConversation();
            }
        } catch (error) {
            console.error("Error: ", error.message);
            if (!conversations.length) {
                createNewConversation();
            }
        } finally {
            setIsLoading(false);
            setIsInitialDataLoaded(true);
        }
    }
    
    const createNewConversation = () => {
        
        const newConversation = {
            id: Date.now(),
            name: '',
            messages: [],
            networkErrorActive: false
        };
        setConversations(prevConversations => [...prevConversations, newConversation]);
        setActiveConversation(newConversation.id);
    }

    useEffect(() => {
        const removeHubSpotChat = () => {
          if (window.HubSpotConversations && window.HubSpotConversations.widget) {
            window.HubSpotConversations.widget.remove();
          }
        };
      
        removeHubSpotChat();
      
        const observer = new MutationObserver(removeHubSpotChat);
        observer.observe(document.body, { childList: true, subtree: true });
      
        return () => observer.disconnect();
      }, []);

    useEffect(() =>{
        if(isInitialDataLoaded && !isStreaming){
            storeAllData(conversations, email, isDemo);
            localStorage.setItem('conversations', JSON.stringify(conversations));
            localStorage.setItem('activeConversation', JSON.stringify(activeConversation));    
        }
    }, [conversations, email, activeConversation, isStreaming])


    return (
        <ConversationsContext.Provider value={{ conversations, setConversations, activeConversation, setActiveConversation, email, setIsStreaming, isDemo, widgetInput  }}>
            <div>
                <FormationsNavBar/>
            <Routes>
                <Route path="/" element={<Home isLoading={isLoading} createNewConversation={createNewConversation}/>} />
                <Route path="/demo" element={<Home isLoading={isLoading} createNewConversation={createNewConversation}/>} />
                <Route path="formations-admin" element={<AdminView />}/>
                <Route path="/view-conversation/:conversation_id/:email" element={<StaticConversation />} />
                <Route path="/:userId" element={<Home isLoading={isLoading} createNewConversation={createNewConversation}/>} />
            </Routes>
            </div>
        </ConversationsContext.Provider>
    );
}

export default App;