const productionURLs = [
    "max.formationscorp.com",
    "formations-assistant.azurewebsites.net",
    "formations-assistant-staging.azurewebsites.net"
  ];
  
const is_production = productionURLs.includes(window.location.hostname);
const BACKEND_URL = is_production ? '' : 'http://localhost:5000';
  
export const getChatCompletion = async (messages, callback, onNetworkErrorCallback, onStreamingStatusChange) => {
    if (messages.messages.length > 0 && messages.messages[0].role === 'assistant') {
        messages.messages[0].content = "Hi, I'm Max, Formations experimental chat experience for prospective customers! My goal is to make it easy for you to learn if Formations can help you achieve your tax and accounting goals. I will ask you questions but you are also free to ask me questions about Formations and about tax and accounting for small businesses.\n\n Here are some things you can ask:";
    }

    const isDemo = localStorage.getItem('isDemo');
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ messages, is_production, isDemo})
    };

    try {
        const response = await fetch(`${BACKEND_URL}/chat-completion`, requestOptions);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const reader = response.body.getReader();
        while (true) {
            // if (Math.random() < 0.10) { // 15% chance
            //     console.error('Simulated network error');
            //     onNetworkErrorCallback();
            //     return; // Stop processing further
            // }
            try {
                const { done, value } = await reader.read();
                if (done) {
                    callback(null, true);
                    onStreamingStatusChange(false);
                    break;
                }

                onStreamingStatusChange(true);
                const decodedChunk = new TextDecoder("utf-8").decode(value);
                try {
                    const jsonChunk = JSON.parse(decodedChunk);
                    if (jsonChunk && jsonChunk.endOfStream) {
                        callback(null, true);
                        break;
                    }
                    callback(decodedChunk, false);
                } catch (e) {
                    callback(decodedChunk, false);
                }
            } catch (err) {
                console.error('Error in streaming', err);
                onNetworkErrorCallback();
                break;
            }
        }
    } catch (error) {
        console.error('Fetch error:', error);
        onNetworkErrorCallback();
    }
}

export const storeShareableConversation = async (conversation) => {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({conversation: conversation, is_production:is_production})
    };
    const response = await fetch(`${BACKEND_URL}/store-shareable-conversation`, requestOptions);
    
    if (!response.ok) {
        const text = await response.text();
        throw new Error(`HTTP error! Status: ${response.status}, Message: ${text}`);
    }

    const data = await response.json();


    return data;
}

export const getShareableConversation = async (conversation_id, email, isDemo) => {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({conversation_id, email, is_production, isDemo})
    };

    const response = await fetch(`${BACKEND_URL}/get-shareable-conversation`, requestOptions);

    if (!response.ok) {
        const text = await response.text();
        throw new Error(`HTTP error! Status: ${response.status}, Message: ${text}`);
    }

    const data = await response.json();

    return data;
}

export const archiveConversation = async (conversation, email) => {
    const payload = {
        conversation_id: conversation.id,
        messages: conversation.messages,
        email: email,
        is_production: is_production
    };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };

    const response = await fetch(`${BACKEND_URL}/archive-conversation`, requestOptions);
    const data = await response.json();

    return data;
}

export const archiveAllConversations = async (conversations, email) => {
    const payload = {
        conversations : conversations,
        email: email,
        is_production: is_production
    };

    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type' : 'application/json'},
        body: JSON.stringify(payload)
    };

    const response = await fetch(`${BACKEND_URL}/archive-all-conversations`, requestOptions);
    const data = await response.json();

    return data;
}

export const validatePasscode = async (inputPasscode) => {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ passcode: inputPasscode })
    };

    try {
        const response = await fetch(`${BACKEND_URL}/verify-passcode`, requestOptions);
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Error verifying passcode');
        }
        const data = await response.json();
        return data.isVerified;
    } catch (error) {
        console.error('Error verifying passcode:', error);
        alert('Failed to verify passcode!');
        return false;
    }
};


export const getAllConversations = async (email, isDemo) => {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({email, isDemo, is_production})
    };

    const response = await fetch (`${BACKEND_URL}/get-conversations`, requestOptions);
    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Error fetching data');
    }
    const data = await response.json();
    return data;
}

export const storeAllData = async (messages, email, isDemo) => {
    if (!email || email.trim() === "") {
        console.error("Email is empty or null. Data not stored.");
        return { message: "Error: Email is empty or null" };
    }

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({messages, email, isDemo, is_production})
    };

    const response = await fetch(`${BACKEND_URL}/store-data`, requestOptions);
    const data = await response.json();

    return data;
}

export const fetchHubspotUserData = async (hubspotId) => {
    try {
      const response = await fetch(`${BACKEND_URL}/get-contact-info/${hubspotId}`);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

export const submitContactForm = async (firstname, lastname, email, phoneNumber, reason, conversation_id, hubspot_id, isDemo, conversations, assignedEmail, question, additionalComments) => {
    
    // const activeConversationId = localStorage.getItem('activeConversation');
    const activeConversationId = localStorage.getItem('activeConversation');;
    const userEmail = localStorage.getItem('userEmail');
    let conversationsArray;
    try {
        conversationsArray = JSON.parse(conversations);
    } catch (e) {
        console.error("Error parsing conversations JSON:", e);
        return { message: "Error: Invalid conversations format" };
    }
    const activeConversation = conversationsArray.find(conv => conv.id === parseInt(activeConversationId));
    if (!userEmail || userEmail.trim() === "") {
        console.error("User email is empty or null. Data not submitted.");
        return { message: "Error: User email is empty or null" };
    }

    const payload = {
        hubspot_id,
        conversation_id,
        firstname,
        lastname,
        email,
        assignedEmail,
        is_production,
        phoneNumber,
        activeConversationId,
        activeConversation: activeConversation ? activeConversation.messages : [],
        userEmail,
        reason,
        isDemo,
        question,
        additionalComments
    };

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };

    const response = await fetch(`${BACKEND_URL}/submit-contact-form`, requestOptions);
    const data = await response.json();

    return data;
}


export const getConversationSummary = async (messages) => {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({messages})
    };
    const response = await fetch (`${BACKEND_URL}/get-summary`, requestOptions);
    const data = await response.json();

    if(!response.ok){
        const text = await response.text();
        throw new Error(`HTTP error! Status: ${response.status}, Message: ${text}`);
    }
    return data;
}

export const getAllConversationsAdmin = async (inputPasscode) => {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({is_production, inputPasscode})
    }

    const response = await fetch(`${BACKEND_URL}/get-all-conversations`, requestOptions);
    const data = await response.json();

    return data;
}

export const updateStateByState = async (file) => {
    const formData = new FormData();
    formData.append('file', file); 
    const requestOptions = {
        method: 'POST',
        body: formData
    };

    const response = await fetch(`${BACKEND_URL}/update_state_by_state`, requestOptions);
    const data = await response.json();

    return data;
};
