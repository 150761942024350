import React, { useState, useEffect} from 'react';
import { submitContactForm, fetchHubspotUserData, storeShareableConversation} from '../api'; // Adjust the import path as needed
import './ContactFormations.css';
import { useParams } from 'react-router-dom';


function ContactFormations({conversationID, reason, setIsContactModalVisible, userQuestion}) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [assignedEmail, setAssignedEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [submissionStatus, setSubmissionStatus] = useState('');
  const [hubspotID, setHubspotID] = useState('');
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDemo, setIsDemo] = useState(localStorage.getItem('isDemo') === 'true');
  const [showDemoMessage, setShowDemoMessage] = useState(isDemo);
  const [conversation, setConversation] = useState(localStorage.getItem('conversations'))
  const [isIframeVisible, setIsIframeVisible] = useState(false);
  const [question, setQuestion] = useState(userQuestion.content); // Preloaded with userQuestion
  const [additionalComments, setAdditionalComments] = useState('');

  // useEffect(() => {
  //   const userEmail = localStorage.getItem('userEmail');
  //   setAssignedEmail(userEmail);
  //   setShowDemoMessage(isDemo);
  //   async function autoFill() {
  //     if (userEmail && !userEmail.includes('-')) {
  //       try {
  //         setHubspotID(userEmail)
  //         const data = await fetchHubspotUserData(userEmail);
  //         if (data.properties) {
  //           setFirstName(data.properties.firstname || '');
  //           setLastName(data.properties.lastname || '');
  //           setEmail(data.properties.email || '');
  //           setPhoneNumber(data.properties.phone || '');
  //         }
  //       } catch (error) {
  //         console.error('Error fetching data:', error);
  //       }
  //     }
  //   }
  
  //   autoFill();
  // }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
        document.body.removeChild(script);
    };
}, []);
  
  const shareChat = () => {
    const conversationsString = conversation;
    const activeConversationId = conversationID;

    if (conversationsString) {
        const conversations = JSON.parse(conversationsString);
        const activeConv = conversations.find(conv => conv.id === Number(activeConversationId));
        storeShareableConversation(activeConv);
    } else {
        console.log('No conversations found in localStorage');
    }
}


  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      shareChat();
      setIsFormVisible(false);
      if (!reason.includes('question')) {
        setIsIframeVisible(true);
        setQuestion('');
      }

      const response = await submitContactForm(firstName, lastName, email, phoneNumber, reason, conversationID, hubspotID, isDemo, conversation, assignedEmail, question, additionalComments);
      if (response.message === 'Contact form data stored successfully') {
        setIsFormVisible(false);
        if (!reason.includes('question')) {
          setIsIframeVisible(true);
        }else{
          handleCloseButtonClick();
        }
        
      } else {
        setSubmissionStatus('Submission failed. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmissionStatus('Error occurred. Please try again.');
    }
    setIsSubmitting(false);
  };

  const handleDemoButtonClick = () => {
    setShowDemoMessage(false);
  };

  const handleCloseButtonClick = () => {
    setIsContactModalVisible(false);
  }

  const iframeSrc = `https://get.formationscorp.com/meetings/uribarjoseph/max-sales-scheduler?embed=true&email=${email}&firstname=${firstName}&lastname=${lastName}`;

  return (
    <div className="cf-modal-overlay">
      <div className="cf-modal-content">
        <div className="cf-contact-form-container">
          {isIframeVisible ? (
            <>
            <h1>Contact Formations</h1>
            <iframe
            className="meetings-iframe-container"
            src={iframeSrc}
            frameBorder="0"
            allowFullScreen
            style={{ width: '100%', height: '650px' }}
        ></iframe>
        <button type="button" className="cf-close-button" onClick={handleCloseButtonClick}>Close</button>
        </>
          ) : (
            <>
              <h1>{reason.includes('question') ? 'Submit Question' : 'Contact Formations'}</h1>
              {showDemoMessage ? (
                <>
                  <p className="cf-demo-message">Thank you for demoing Max, our AI-powered guide to tax savings! If you'd like to contact Formations, please continue below. Otherwise, you can close this tab.</p>
                  <div className="cf-form-actions">
                      <button type="button" className="cf-close-button" onClick={handleCloseButtonClick}>Close</button>
                      <button className="cf-demo-button" onClick={handleDemoButtonClick}>Continue to Form</button>
                    </div>
                </>
              ) : (
                isFormVisible && (
                  <form onSubmit={handleSubmit}>
                    <div className="cf-input-group">
                      <label htmlFor="firstName">First Name:*</label>
                      <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="cf-input-group">
                      <label htmlFor="lastName">Last Name:*</label>
                      <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="cf-input-group">
                      <label htmlFor="email">Email:*</label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="cf-input-group">
                      <label htmlFor="phoneNumber">Phone Number:</label>
                      <input
                        type="tel"
                        id="phoneNumber"
                        name="phoneNumber"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </div>
                    {reason === 'question' && (
                        <>
                          <div className="cf-input-group">
                            <label htmlFor="question">Question:</label>
                            <input
                              type="text"
                              id="question"
                              name="question"
                              value={question}
                              onChange={(e) => setQuestion(e.target.value)}
                            />
                          </div>
                          <div className="cf-input-group">
                            <label htmlFor="additionalComments">Additional Comments:</label>
                            <input
                              type="text"
                              id="additionalComments"
                              name="additionalComments"
                              value={additionalComments}
                              onChange={(e) => setAdditionalComments(e.target.value)}
                            />
                          </div>
                        </>
                      )}
                    <div className="cf-form-actions">
                      <button type="button" className="cf-close-button" onClick={handleCloseButtonClick}>Close</button>
                      <button type="submit" className="cf-submit-button">Submit</button>
                    </div>
                  </form>
                )
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
  
}

export default ContactFormations;
