import React, { useState } from 'react';
import { getAllConversationsAdmin } from '../api';
import './AdminView.css';
import {unparse} from 'papaparse';
import Papa from 'papaparse';
import { updateStateByState, validatePasscode } from '../api';

const AdminView = () => {
    const [conversations, setConversations] = useState([]);
    const [keyKGAs, setKeyKGAs] = useState([]);
    const [newQuestions, setNewQuestions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [inputPasscode, setInputPasscode] = useState('');
    const [isVerified, setIsVerified] = useState(false);

    const verifyPasscode = async () => {
        const isVerified = await validatePasscode(inputPasscode);
        if (isVerified) {
            console.log("Fetching Data");
            setIsVerified(true);
            fetchData();
        } else {
            alert('Incorrect passcode!');
        }
    };
    

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const allData = await getAllConversationsAdmin(inputPasscode);
            console.log(allData); // Debug to see the structure of allData
            
            // Assuming new_questions should be an array of objects with 'question' and 'count' properties
            const newQuestions = allData.new_questions instanceof Object && !(allData.new_questions instanceof Array)
                ? Object.entries(allData.new_questions).map(([question, count]) => ({question, count}))
                : (allData.new_questions || []).sort((a, b) => b.count - a.count);
    
            const keyKGAs = Array.isArray(allData.key_kgas)
                ? allData.key_kgas.sort((a, b) => b.count - a.count)
                : [];
            const sortedConversations = Array.isArray(allData.data)
                ? allData.data.sort((a, b) => b.id - a.id)
                : [];
    
            setConversations(sortedConversations);
            setKeyKGAs(keyKGAs);
            setNewQuestions(newQuestions);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false);
        }
    };
    
    

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' }) +
               " " + 
               date.toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit' });
    };
    
    const exportAllData = () => {
        const downloadCSV = (data, filename) => {
            const csv = unparse(data, {header: true});
            const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            const csvURL = window.URL.createObjectURL(csvData);
            const tempLink = document.createElement('a');
            tempLink.href = csvURL;
            tempLink.setAttribute('download', filename);
            document.body.appendChild(tempLink); 
            tempLink.click();
            document.body.removeChild(tempLink);
        };
        
    
        downloadCSV(newQuestions.map(q => ({
            Question: q.question,
            Count: q.count
        })), 'new_questions.csv');
    
        downloadCSV(keyKGAs.map(k => ({
            Question: k.question,
            Count: k.count
        })), 'key_kgas.csv');
    
        downloadCSV(conversations.map(conv => ({
            "User ID": conv.email,
            "Conversation ID": conv.id,
            "Qualification Status": conv.qualification_status,
            "Reason": conv.reason,
            "Date Created": formatDate(conv.id),
            "# of User Messages": conv.messages.filter(msg => msg.role === 'user').length,
            "View Conversation": `${window.location.origin}/view-conversation/${conv.id}/${conv.email}`
        })), 'all_conversations.csv');
        
    };

    const triggerFileInputClick = () => {
        document.getElementById('fileInput').click();
    };

    const handleFileUpload = (event) => {
        const fileInput = event.target;
        const file = fileInput.files[0];
        if (file) {
            validateCsv(file).then(isValid => {
                if (isValid) {
                    updateStateByState(file)
                        .then(data => {
                            alert('Upload successful!')
                            document.getElementById('fileUploadForm').reset();
                        })
                        .catch(error => {
                            alert('Upload failed, please try again.');
                            document.getElementById('fileUploadForm').reset();
                        });
                } else {
                    alert('CSV format is invalid. Please check the file and try again.');
                    document.getElementById('fileUploadForm').reset();
                }
            }).catch(error => {
                alert('Validation error, please try again.');
                document.getElementById('fileUploadForm').reset();
            });
        }
    };
    
    const validateCsv = (file) => {
        return new Promise((resolve, reject) => {
            Papa.parse(file, {
                complete: (result) => {
                    const rows = result.data;
                    const headers = ["StateName", "State", "Real Estate", "Real Estate Caveats"];
                    if (!headers.every((header, index) => rows[0][index] === header)) {
                        resolve({ isValid: false, message: "Header row does not match the required format." });
                        return;
                    }
                    if (rows.length !== 51) {
                        resolve({ isValid: false, message: "The CSV file must have 51 rows, including the header." });
                        return;
                    }
                    const hasMissingValues = rows.slice(1).some(row => row.includes("") || row.length < 4);
                    if (hasMissingValues) {
                        resolve({ isValid: false, message: "One or more rows are missing values." });
                        return;
                    }
                    resolve({ isValid: true, message: "" });
                },
                error: (error) => {
                    console.error('Error parsing CSV:', error);
                    reject(error);
                },
                skipEmptyLines: true,
                header: false
            });
        });
    };
    

    

    if (!isVerified) {
        return (
            <div className="passcode-verification">
                <input
                    type="password"
                    value={inputPasscode}
                    onChange={(e) => setInputPasscode(e.target.value)}
                    placeholder="Enter Passcode"
                />
                <button onClick={verifyPasscode}>Verify</button>
            </div>
        );
    }

    return (
        <div className="admin-view-container">
            <div className="admin-view-header">
                <h1>Admin View</h1>
                <div className="button-group">
                    <button onClick={triggerFileInputClick} className="header-button">Upload States</button>
                    <form id="fileUploadForm">
                        <input
                            type="file"
                            id="fileInput"
                            accept=".csv"
                            onChange={handleFileUpload}
                            style={{ display: 'none' }}
                        />
                    </form>
                    <button onClick={exportAllData} className="header-button">Export Data</button>
                </div>
            </div>

            {isLoading ? (
                <div className="loading-spinner">
                    <div className="spinner"></div>
                </div>
            ) : (
                <>
                    <div className="side-by-side-tables">
                        <div className="table-container">
                            <h2>New Questions</h2>
                            <div className="table-scroll question-table-scroll">
                            <table className="admin-view-table question-table">
                                <thead>
                                    <tr>
                                        <th>Question</th>
                                        <th>Count</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {newQuestions.length > 0 ? (
                                        newQuestions.map((question, index) => (
                                            <tr key={index}>
                                                <td>{question.question}</td>
                                                <td>{question.count}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="2">No new questions found.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        </div>
                        <div className="table-container">
                            <h2>Top KGA's Retrieved</h2>
                            <div className="table-scroll kga-table-scroll">
                                <table className="admin-view-table kga-table">
                                    <thead>
                                        <tr>
                                            <th>Question</th>
                                            <th>Count</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {keyKGAs.length > 0 ? (
                                            keyKGAs.map((kga, index) => (
                                                <tr key={index}>
                                                    <td>{kga.question}</td>
                                                    <td>{kga.count}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="2">No key KGAs found.</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                    <div className="table-container">
                    <h2>All Conversations</h2>
                    <div className="table-scroll conversations-table-scroll">
                        <table className="admin-view-table conversation-table">
                            <thead>
                                <tr>
                                    <th>User ID</th>
                                    <th>Conversation ID</th>
                                    <th>Qualification Status</th>
                                    <th>Reason</th>
                                    <th>Date Created</th>
                                    <th># of User Messages</th>
                                    <th>View Conversation</th>
                                </tr>
                            </thead>
                            <tbody>
                                {conversations.length > 0 ? (
                                    conversations.map((conv, index) => (
                                        <tr key={index}>
                                            <td>{conv.email}</td>
                                            <td>{conv.id}</td>
                                            <td>{conv.qualification_status}</td>
                                            <td className="reason-cell">{conv.reason}</td>
                                            <td>{formatDate(conv.id)}</td>
                                            <td>{conv.messages.filter(msg => msg.role === 'user').length}</td>
                                            <td>
                                                <a href={`/view-conversation/${conv.id}/${conv.email}`} target="_blank" rel="noopener noreferrer">View</a>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="7">No conversations found.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    </div>
                    
                </>
            )}
        </div>

    );
    
};

export default AdminView;
